<template>
	<div class="container-fluid dashboard">
		<div class="row mb-4">
			<div class="col-12">
				<h1 class="dashboard-title">판매성과</h1>
				<p class="dashboard-subtitle">상품 판매 현황 및 성과를 한눈에 확인하세요.</p>
			</div>
		</div>

		<div class="row mb-4">
			<div class="col-lg-4 col-md-6 col-sm-12">
				<div class="date-range-section">
					<div class="date-inputs d-flex">
						<v-menu
						ref="startMenu"
						v-model="startMenu"
						:close-on-content-click="false"
						:return-value.sync="dateRange.start"
						transition="scale-transition"
						offset-y
						min-width="auto"
						class="mr-2"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
								v-model="dateRange.start"
								label="시작 날짜"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
								dense
								hide-details
								class="date-input"
								></v-text-field>
							</template>
							<v-date-picker v-model="dateRange.start" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="startMenu = false">취소</v-btn>
								<v-btn text color="primary" @click="$refs.startMenu.save(dateRange.start)">확인</v-btn>
							</v-date-picker>
						</v-menu>
						<v-menu
						ref="endMenu"
						v-model="endMenu"
						:close-on-content-click="false"
						:return-value.sync="dateRange.end"
						transition="scale-transition"
						offset-y
						min-width="auto"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
								v-model="dateRange.end"
								label="종료 날짜"
								prepend-icon="mdi-calendar"
								readonly
								v-bind="attrs"
								v-on="on"
								dense
								hide-details
								class="date-input"
								></v-text-field>
							</template>
							<v-date-picker v-model="dateRange.end" no-title scrollable>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="endMenu = false">취소</v-btn>
								<v-btn text color="primary" @click="$refs.endMenu.save(dateRange.end)">확인</v-btn>
							</v-date-picker>
						</v-menu>
					</div>
					<v-btn color="primary" @click="fetchData" class="mt-2 fetch-btn">조회</v-btn>
				</div>
			</div>
		</div>
	
		<div class="row mb-4">
			<div class="col-md-6 col-xl-3 mb-4">
				<v-card class="dashboard-card">
				<v-card-text>
					<h6 class="card-subtitle mb-2">총 판매 수량</h6>
					<h3 class="card-title font-weight-bold">{{ salescnt }}</h3>
					<v-icon color="success">mdi-cart-outline</v-icon>
					<span :class="['ml-2', salescntChange >= 0 ? 'success--text' : 'error--text']">
					{{ salescntChange >= 0 ? '+' : '' }}{{ salescntChange }}
					</span>
				</v-card-text>
				</v-card>
			</div>
			<div class="col-md-6 col-xl-3 mb-4">
				<v-card class="dashboard-card">
				<v-card-text>
					<h6 class="card-subtitle mb-2">총 판매 매출</h6>
					<h3 class="card-title font-weight-bold">{{ salesamount | currency }}</h3>
					<v-icon color="info">mdi-currency-krw</v-icon>
					<span :class="['ml-2', salesamountChange >= 0 ? 'success--text' : 'error--text']">
					{{ salesamountChange >= 0 ? '+' : '' }}{{ salesamountChange | currency }}
					</span>
				</v-card-text>
				</v-card>
			</div>
			<div class="col-md-6 col-xl-3 mb-4">
				<v-card class="dashboard-card">
				<v-card-text>
					<h6 class="card-subtitle mb-2">총 판매 수익</h6>
					<h3 class="card-title font-weight-bold">{{ totalProfit | currency }}</h3>
					<v-icon color="primary">mdi-currency-krw</v-icon>
					<span :class="['ml-2', totalProfitChange >= 0 ? 'success--text' : 'error--text']">
					{{ totalProfitChange >= 0 ? '+' : '' }}{{ totalProfitChange | currency }}
					</span>
				</v-card-text>
				</v-card>
			</div>
			<div class="col-md-6 col-xl-3 mb-4">
				<v-card class="dashboard-card">
				<v-card-text>
					<h6 class="card-subtitle mb-2">총 광고 수익</h6>
					<h3 class="card-title font-weight-bold">{{ totalAdRevenue | currency }}</h3>
					<v-icon color="warning">mdi-currency-krw</v-icon>
					<span :class="['ml-2', totalAdRevenueChange >= 0 ? 'success--text' : 'error--text']">
					{{ totalAdRevenueChange >= 0 ? '+' : '' }}{{ totalAdRevenueChange | currency }}
					</span>
				</v-card-text>
				</v-card>
			</div>
		</div>
	
		<div class="row mb-4">
			<div class="col-md-6 mb-4">
			<v-card class="dashboard-card">
				<v-card-title class="card-title">판매 추이</v-card-title>
				<v-card-text>
				<apexchart type="line" height="350" :options="salesChartOptions" :series="salesChartSeries"></apexchart>
				</v-card-text>
			</v-card>
			</div>
			<div class="col-md-6 mb-4">
			<v-card class="dashboard-card">
				<v-card-title class="card-title">상위 판매 상품</v-card-title>
				<v-card-text>
				<apexchart type="bar" height="350" :options="topProductsChartOptions" :series="topProductsChartSeries"></apexchart>
				</v-card-text>
			</v-card>
			</div>
		</div>
	
		<div class="row mb-4">
			<div class="col-12">
				<v-card class="dashboard-card">
					<v-card-title class="card-title">
						상품 목록
						<v-spacer></v-spacer>
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="검색"
							single-line
							hide-details
							dense
							class="search-input"
						></v-text-field>
					</v-card-title>
					<v-data-table
					:headers="headers"
					:items="saleslists"
					:search="search"
					:items-per-page="10"
					class="elevation-1"
					>
						<template v-slot:column.header>
							<tr>
								<th style="text-align: center;">상품정보</th>
								<th style="text-align: center;">판매량</th>
								<th style="text-align: center;">매출</th>
								<th style="text-align: center;">수익</th>
								<th style="text-align: center;">일반수익</th>
								<th style="text-align: center;">광고수익</th>
								<th style="text-align: center;">광고비</th>
								<th colspan="3" style="text-align: center;">검색</th>
								<th colspan="3" style="text-align: center;">비검색</th>
								<th style="text-align: center;">구매DDAY</th>
								<th style="text-align: center;">조회수</th>
								<th style="text-align: center;">반품율</th>
								<th style="text-align: center;">재고</th>
							</tr>
							<tr>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
								<th>CTR</th>
								<th>CVR</th>
								<th>광고ROI</th>
								<th>CTR</th>
								<th>CVR</th>
								<th>광고ROI</th>
								<th></th>
								<th></th>
								<th></th>
								<th></th>
							</tr>
						</template>
						<template v-slot:item.상품정보="{ item }">
							<div class="d-flex align-center">
								<v-img :src="item.썸네일" max-width="50" max-height="50" class="mr-2"></v-img>
								<div>
									<div>{{ item.판매상품명 }}</div>
									<div class="caption">SKU ID: {{ item.SKUID }}</div>
								</div>
							</div>
						</template>
						<template v-slot:item.판매량="{ item }">
							{{ item.판매량}}
						</template>
						<template v-slot:item.일반판매량="{ item }">
							{{ item.일반판매량}}
						</template>
						<template v-slot:item.광고판매량="{ item }">
							{{ item.광고판매량}}
						</template>
						<template v-slot:item.매출="{ item }">
							{{ item.매출 | currency }}
						</template>
						<template v-slot:item.수익="{ item }">
							{{ item.수익 | currency }}
						</template>
						<template v-slot:item.일반수익="{ item }">
							{{ item.일반수익 | currency }}
						</template>
						<template v-slot:item.광고수익="{ item }">
							{{ item.광고수익 | currency }}
						</template>
						<template v-slot:item.광고비="{ item }">
							{{ item.광고비 | currency }}
						</template>
						<template v-slot:item.광고ROI검색="{ item }">
							{{ item.광고ROI검색 }}%
						</template>
						<template v-slot:item.광고ROI비검색="{ item }">
							{{ item.광고ROI비검색 }}%
						</template>
						<template v-slot:item.CTR검색="{ item }">
							{{ item.CTR검색 }}%
						</template>
						<template v-slot:item.CVR검색="{ item }">
							{{ item.CVR검색 }}%
						</template>
						<template v-slot:item.CTR비검색="{ item }">
							{{ item.CTR비검색 }}%
						</template>
						<template v-slot:item.CVR비검색="{ item }">
							{{ item.CVR비검색 }}%
						</template>
						<template v-slot:item.구매DDAY="{ item }">
							{{ item.구매DDAY}}
						</template>
						<template v-slot:item.조회수="{ item }">
							{{ item.조회수}}
						</template>
						<template v-slot:item.반품율="{ item }">
							{{ item.반품율}}%
						</template>
						<template v-slot:item.재고="{ item }">
							{{ item.재고}}
						</template>
					</v-data-table>
				</v-card>
			</div>
		</div>
	
		<v-dialog v-model="detailDialog" max-width="800px">
			<v-card>
			<v-card-title class="headline">
				상품 상세 정보
			</v-card-title>
			<v-card-text>
				<v-row>
				<v-col cols="4">
					<v-img :src="selectedItem.썸네일" contain max-height="200"></v-img>
				</v-col>
				<v-col cols="8">
					<p><strong>상품명:</strong> {{ selectedItem.판매상품명 }}</p>
					<p><strong>노출ID:</strong> {{ selectedItem.노출ID }}</p>
					<p><strong>옵션ID:</strong> {{ selectedItem.옵션ID }}</p>
					<p><strong>판매량:</strong> {{ selectedItem.판매량 }}</p>
					<p><strong>매출:</strong> {{ selectedItem.매출 | currency }}</p>
					<p><strong>수익:</strong> {{ selectedItem.수익 | currency }}</p>
					<p><strong>일반수익:</strong> {{ selectedItem.일반수익 | currency }}</p>
					<p><strong>광고수익:</strong> {{ selectedItem.광고수익 | currency }}</p>
					<p><strong>광고비:</strong> {{ selectedItem.광고비 | currency }}</p>
					<p><strong>광고ROI(검색):</strong> {{ selectedItem.광고ROI검색 }}%</p>
					<p><strong>광고ROI(비검색):</strong> {{ selectedItem.광고ROI비검색 }}%</p>
					<p><strong>상품성(검색):</strong> {{ selectedItem.상품성검색 }}</p>
					<p><strong>상품성(비검색):</strong> {{ selectedItem.상품성비검색 }}</p>
					<p><strong>구매D-Day:</strong> {{ selectedItem.구매DDAY }}</p>
					<p><strong>조회수:</strong> {{ selectedItem.조회수 }}</p>
					<p><strong>반품율:</strong> {{ selectedItem.반품율 }}</p>
					<p><strong>재고:</strong> {{ selectedItem.재고 }}</p>
				</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="detailDialog = false">닫기</v-btn>
			</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
  </template>
  
  <script>
  import Vue from 'vue';
  import VueApexCharts from '../../../components/charts/ApexChart';
  import axios from 'axios';
  
  Vue.use(VueApexCharts);
  
  export default {
	name: 'ProductDashboard',
	components: {
		apexchart: VueApexCharts,
	},
	data() {
		return {
			salescnt: 0,
			salesamount: 0,
			totalProfit: 0,
			totalAdRevenue: 0,
			salescntChange: 0,
			salesamountChange: 0,
			totalProfitChange: 0,
			totalAdRevenueChange: 0,
			saleslists: [],
			search: '',
			headers: [
				{ text: '상품정보', value: '상품정보',align: 'center' },
				{ text: '판매량', value: '판매량',align: 'center' },
				{ text: '매출', value: '매출',align: 'center' },
				{ text: '수익', value: '수익',align: 'center' },
				{ text: '일반수익', value: '일반수익',align: 'center' },
				{ text: '광고수익', value: '광고수익',align: 'center' },
				{ text: '광고비', value: '광고비',align: 'center' },
				{ text: 'CTR', value: 'CTR검색',align: 'center' },
				{ text: 'CVR', value: 'CVR검색',align: 'center' },
				{ text: '광고ROI', value: '광고ROI검색',align: 'center' },
				{ text: 'CTR', value: 'CTR비검색',align: 'center' },
				{ text: 'CVR', value: 'CVR비검색',align: 'center' },
				{ text: '광고ROI', value: '광고ROI비검색',align: 'center' },
				{ text: '구매D-DAY', value: '구매DDAY',align: 'center' },
				{ text: '조회수', value: '조회수',align: 'center' },
				{ text: '반품율', value: '반품율',align: 'center' },
				{ text: '재고', value: '재고',align: 'center' },
			],
			salesChartOptions: {
				chart: { id: 'sales-trend' },
				xaxis: { type: 'datetime' },
				yaxis: { title: { text: '판매량' } },
			},
			salesChartSeries: [{ name: '판매량', data: [] }],
			topProductsChartOptions: {
				chart: { id: 'top-products' },
				xaxis: { type: 'category' },
				yaxis: { title: { text: '판매량' } },
			},
			topProductsChartSeries: [{ name: '판매량', data: [] }],
			detailDialog: false,
			selectedItem: {},
			dateRange: {
				start: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
				end: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
			},
			startMenu: false,
			endMenu: false,
		};
	},
	methods: {
		async fetchData() {
			try {
				const response = await axios.get('/api/product-data', {
					params: {
						start_date: this.dateRange.start,
						end_date: this.dateRange.end
					}
				});
				this.saleslists = response.data.map(item => ({
					...item,
					date: new Date(item.date).toLocaleDateString('ko-KR'),
					상품정보: `${item.판매상품명} (${item.SKUID})`,
				}));
				
				// 이전 기간의 데이터를 가져옵니다.
				const previousStartDate = this.getPreviousPeriodStart();
				const previousEndDate = this.getPreviousPeriodEnd();
				const previousResponse = await axios.get('/api/product-data', {
				params: {
					start_date: previousStartDate,
					end_date: previousEndDate
				}
				});
				const previousData = previousResponse.data;
				
				this.updateDashboardData(previousData);
				this.updateCharts();
			} catch (error) {
				console.error('데이터 불러오기 실패:', error);
			}
		},
		getPreviousPeriodStart() {
			const start = new Date(this.dateRange.start);
			const end = new Date(this.dateRange.end);
			const diff = end.getTime() - start.getTime();
			return new Date(start.getTime() - diff).toISOString().substr(0, 10);
		},

		getPreviousPeriodEnd() {
			return this.dateRange.start;
		},
		updateDashboardData(yesterdayData) {
			const calculateTotal = (list, key) => list.reduce((sum, item) => sum + (item[key] || 0), 0);

			this.salescnt = calculateTotal(this.saleslists, '판매량');
			this.salesamount = calculateTotal(this.saleslists, '매출');
			this.totalProfit = calculateTotal(this.saleslists, '수익');
			this.totalAdRevenue = calculateTotal(this.saleslists, '광고수익');

			// 전날 데이터와 비교하여 변화량 계산
			this.salescntChange = this.salescnt - calculateTotal(yesterdayData, '판매량');
			this.salesamountChange = this.salesamount - calculateTotal(yesterdayData, '매출');
			this.totalProfitChange = this.totalProfit - calculateTotal(yesterdayData, '수익');
			this.totalAdRevenueChange = this.totalAdRevenue - calculateTotal(yesterdayData, '광고수익');
		},
		updateCharts() {
			// 판매 추이 차트 업데이트
			this.salesChartSeries[0].data = this.saleslists.map(item => [new Date(item.date).getTime(), item.판매량]);
	
			// 상위 판매 상품 차트 업데이트
			const topProducts = this.saleslists
			.sort((a, b) => b.판매량 - a.판매량)
			.slice(0, 10);
			this.topProductsChartSeries[0].data = topProducts.map(item => ({
				x: item.판매상품명,
				y: item.판매량
			}));
		},
		showDetails(item) {
			this.selectedItem = item;
			this.detailDialog = true;
		},
	},
	filters: {
		currency(value) {
			return new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(value);
		},
		decimal(value, decimals) {
			return Number(value).toFixed(decimals);
		},
	},
	mounted() {
		this.fetchData();
	},
  };
  </script>
  
  <style scoped>
  .dashboard {
    background-color: #f5f5f5;
    padding: 20px;
  }
  .dashboard-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  .dashboard-subtitle {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  .dashboard-card {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .card-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  .card-subtitle {
    font-size: 14px;
    color: #666;
  }
  .date-range-section {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .date-inputs {
    margin-bottom: 15px;
  }
  .date-input {
    font-size: 14px;
  }
  .fetch-btn {
    width: 100%;
    margin-top: 10px;
  }
  .search-input {
    max-width: 300px;
    font-size: 14px;
  }
  .v-data-table {
    background-color: white;
  }
  @media (max-width: 600px) {
    .dashboard {
      padding: 10px;
    }
    .date-range-section {
      padding: 10px;
    }
    .date-inputs {
      flex-direction: column;
    }
    .date-input {
      margin-bottom: 10px;
    }
    .search-input {
      max-width: 100%;
    }
	.v-data-table thead th {
	text-align: center;
	}
	.v-data-table thead tr:first-child th {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12); /* 상단 헤더 구분선 */
	padding-bottom: 8px;
	}

	.v-data-table thead tr:last-child th {
	padding-top: 8px;
	}
  }
  </style>